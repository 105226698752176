import React, { useEffect } from "react";
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from "react-router-dom";

import {MainScreen} from "../screens/main.screen";

const AppRoutes: React.FC = () => {

    return (
        <Router>
            <Switch>
                <Route exact path="*">
                    <MainScreen />
                </Route>
            </Switch>
        </Router>
    );
};

export default AppRoutes;
